import { isValueEmpty, useContextData, useSentry } from '#imports'
import { relativeKey, absoluteKey } from '@/utils/nitro/keygen'

export async function useCacheData<T> (key: string | string[], formatKey = true) {
  const driver = useContextData<any>('driver')
  const sentry = useSentry()
  const formattedKey = formatKey ? relativeKey(key) : absoluteKey(key)

  let value: T | undefined

  if (driver.value) {
    const start = Date.now()

    try {
      const data: any = await driver.value.getItem(formattedKey)
      const end = Date.now()

      sentry.addBreadcrumb({
        category: 'cache',
        message: 'Cache data manipulation',
        data: {
          method: 'GET',
          key: formattedKey,
          duration: end - start,
          hasData: Boolean(data)
        },
        level: 'info'
      })

      if (data) {
        value = data
      }
    } catch (err: any) {
      sentry.captureException(err)
    }
  }

  async function addToCache (data: any, ttl = 3600) {
    if (driver.value?.setItem) {
      const start = Date.now()

      if (ttl) {
        ttl += Math.floor(Math.random() * 30000) - 15000
      }

      try {
        await driver.value.setItem(formattedKey, data, { ttl })
        const end = Date.now()

        sentry.addBreadcrumb({
          category: 'cache',
          message: 'Cache data manipulation',
          data: {
            method: 'SET',
            key: formattedKey,
            duration: end - start
          },
          level: 'info'
        })
      } catch (err: any) {
        sentry.captureException(err)
      }
    }
  }

  async function removeFromCache () {
    const start = Date.now()

    if (driver.value?.removeItem) {
      await driver.value?.removeItem(formattedKey, {})
      const end = Date.now()

      sentry.addBreadcrumb({
        category: 'cache',
        message: 'Cache data manipulation',
        data: {
          method: 'REMOVE',
          key: formattedKey,
          duration: end - start
        },
        level: 'info'
      })
    }
  }

  return {
    value,
    addToCache,
    removeFromCache
  }
}

export async function useCacheWrapper<T> (key: string | string[], cb: (() => Promise<T>) | (() => T), formatKey = true) {
  const { value, addToCache } = await useCacheData<T>(key, formatKey)

  if (value) {
    return value
  }

  const result = await cb()
  if (!isValueEmpty(result)) {
    await addToCache(result)
  }

  return result
}
