import type { ApiType } from './services'
import { createApiClients } from './services'
import { useContextData } from '#imports'

export function createApi () {
  const apiInterceptors = useContextData<any>('api-interceptors', [])

  const runtimeConfig = useRuntimeConfig()
  let apiUrl = runtimeConfig.apiGrpcUrlSsr

  if (import.meta.client) {
    apiUrl = runtimeConfig.public.apiGrpcUrlSpa
  } else if (!apiUrl) {
    throw new Error('Create an .env file with NUXT_API_GRPC_URL_SSR property!')
  }

  return createApiClients(apiUrl, apiInterceptors.value)
}

export function useAPI (): ApiType {
  const apiClients = useContextData<any>('api', null)

  if (!apiClients.value) {
    apiClients.value = createApi()
  }

  return apiClients.value
}
