import { computed, toRef } from 'vue'
import { useAPI, useCacheWrapper, useHydrationRef, useHydrationStore, useLocalization, useSharedPromise } from '#imports'

import type { Region } from '@/modules/nuxt-api/models/Region'

import type { LocationCity } from '@/types/locale.d'
import region from '@/settings/region.json'

export type RegionWithName = Region & { name?: string }

type State = {
  regions: Region[]
  testRegionSubdomain: string
  mainRegionId: Record<string, number>
}

export function useConfig () {
  const runtimeConfig = useRuntimeConfig()
  const api = useAPI()

  const state = useHydrationStore<State>('config-store', {
    regions: [],
    testRegionSubdomain: '',
    mainRegionId: {
      ru: 1,
      ua: 2,
      gb: 3,
      kz: 21
    }
  })

  const currentRegion = useHydrationRef<RegionWithName>('current-region', region as Region)

  const isMscRegion = computed(() => currentRegion.value.city?.code === 'ru-mow')
  const isExternalRegion = computed(() => currentRegion.value?.country?.code !== 'ru')
  const isLangSwitching = computed(() => {
    const code = currentRegion.value.country?.code

    return code === 'kz' || code === 'ua' || (code === 'ru' && isMscRegion.value)
  })

  const currentRegionName = computed(() => {
    const { locale } = useLocalization()

    return currentRegion.value.city?.nameMap?.find(l => l[0].slice(0, 2) === locale.value)?.[1]
  })

  async function getRegions () {
    const { getRegions } = api.region()

    state.value.regions = await useSharedPromise('config-regions', () => useCacheWrapper('config-regions', async () => {
      const regionsData = await getRegions()

      return regionsData ?? []
    }))

    return state.value.regions
  }

  async function getRegionsByCountryCode (code: string): Promise<LocationCity[]> {
    const { locale } = useLocalization()
    const regions = await getRegions()

    return (regions || [])
      .filter((region: Region) => region.country?.code === code)
      .filter((region: Region) => !region.isNotPublished)
      .map((region: Region) => ({
        id: region.id,
        domain: region.domain,
        subdomain: region.subdomain || null,
        name: region.city?.nameMap?.find(l => l[0].slice(0, 2) === locale.value)?.[1] || '',
        isNotPublished: region.isNotPublished
      }))
  }

  async function getCountries (): Promise<Region[]> {
    const regions = await getRegions()
    const countriesToChange: Region[] = []

    if (regions) {
      regions.forEach((item: Region) => {
        if (item?.country?.code) {
          const isUA = item.country.code === 'ua'

          if (item.country.code === 'ru' && item.city?.code !== 'ru-mow') { return }
          if (currentRegion.value.country?.code === 'ru' && isUA) { return }

          const countryData: any = {
            ...item,
            allowLocalesList: item.allowLocalesList.map(l => l.slice(0, 2)),
            defaultLocale: item.defaultLocale.slice(0, 2),
            country: item?.country.code && { ...{ code: item.country.code.slice(0, 2) } }
          }
          countriesToChange.push(countryData)
        }
      })
    }

    return countriesToChange
  }

  function getCurrentRegion (regions: Region[] = [], hostUrl?: string) {
    const { locale } = useLocalization()

    const host = hostUrl ?? ''
    let testRegionId: number

    const testRegionsList: any = runtimeConfig.public.testRegionsJson

    if (Array.isArray(testRegionsList)) {
      const testRegion = testRegionsList.find(item => host.includes(item.subDomain))

      if (testRegion) {
        state.value.testRegionSubdomain = testRegion.subDomain
        testRegionId = Number(testRegion.originalRegionId)
      }
    }

    const findRegion = () => {
      return regions
        .sort((a: Region, b: Region) => Number(!a.subdomain?.length) - Number(!b.subdomain?.length))
        .find((el: Region) => {
          if ((host.startsWith('eng') && el.id === state.value.mainRegionId.ru) || (el.id === testRegionId)) { return true }

          const hasRightDomain = runtimeConfig.public.nodeEnv === 'production' ? host.endsWith(`.${el.domain}`) : el.domain === 'ru'
          if (el.subdomain) {
            return host.startsWith(`${el.subdomain}.`) && hasRightDomain
          }
          return hasRightDomain
        }) ?? regions.find(item => item.city?.code === 'ru-mow') ?? regions[0]
    }

    const localeRegion = findRegion() as RegionWithName

    localeRegion.allowLocalesList = localeRegion.allowLocalesList.map(l => l.slice(0, 2))
    localeRegion.defaultLocale = host.startsWith('eng.') ? 'en' : localeRegion.defaultLocale.slice(0, 2)
    localeRegion.name = currentRegion.value.city?.nameMap?.find(l => l[0].slice(0, 2) === locale.value)?.[1]

    if (localeRegion.country?.code) {
      localeRegion.country.code = localeRegion.country.code.slice(0, 2)
    }

    return localeRegion
  }

  function getRegionIdByCountryCode (code?: string) {
    return state.value.mainRegionId[code || 'ru'] || 1
  }

  function isMainRegionInCountry (code?: string) {
    return code ? state.value.mainRegionId[code] === currentRegion.value.id : false
  }

  return {
    getRegions,
    getCountries,
    getRegionsByCountryCode,
    getCurrentRegion,
    getRegionIdByCountryCode,
    isMainRegionInCountry,

    currentRegion,
    isMscRegion,
    isExternalRegion,
    isLangSwitching,
    currentRegionName,

    testRegionSubdomain: toRef(state.value, 'testRegionSubdomain'),
    mainRegionId: toRef(state.value, 'mainRegionId'),
    regions: toRef(state.value, 'regions')
  }
}
